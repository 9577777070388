<template>
   <div>

   </div>
</template>

<script>




export default {
   name: 'App',
   components: {
   },
   data() {
      return {
      };
   },
   mounted() {

   }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P:400,500');
@import './style.css';
</style>
