import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(firestorePlugin)

const firebaseConfig = {
  apiKey: "AIzaSyADoCwHV0bQtn1P-tcXVfHLDkBlsPEeJg0",
  authDomain: "moonshroomiz.firebaseapp.com",
  projectId: "moonshroomiz",
  storageBucket: "moonshroomiz.appspot.com",
  messagingSenderId: "352982670836",
  appId: "1:352982670836:web:c9fd911231b4aa10d8ada1"
};

export const db = firebase.initializeApp(firebaseConfig).firestore();

Vue.config.productionTip = false



new Vue({
  render: h => h(App),
}).$mount('#app')
